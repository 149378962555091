<template>
  <div class="bg-light pt-1 px-2 rounded">
    <div class="pb-2" >


       Pédiatrie


    </div>
    <h3>In progress...</h3>

  </div>




</template>

<script>

import Axios from "@/_services/caller.services";

export default {

  name: "Pediatrie",

  props : ["movment_id", "services_id"],
  data() {
    return {
      showform: false,
      allergiesSpinner: false,
      allergies: [],
      description:"",
      movment :{},
      name:"",
    };
  },

  mounted() {

  },

  methods: {
   getMovemt() {

    Axios.get("/movments/"+ this.movment_id)
    .then(
      (res) => {
        this.movment = res.data.data;
        this.getAllergies(this.movment.patients_id);
        console.log(this.movment);

      })
    .catch((error) => {
      console.error(error);
    });
  },
  }


};




</script>


<style>
.addbtn { padding:0px 5px; }

</style>
