<template>
  <div>

      <h3
      class="card-title text-white rounded p-0 mb-1 text-center"
      style="
      background-color: #0B5D3F;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      "
      >
     <small>Laboratoires</small>
    </h3>



    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <b-button class="p-1" block v-b-toggle.accordion-1 variant="secondary">Nouveaux Examens </b-button>

          <small class="float-right " style="">
            <a @click="showform=true"  class="btn btn-sm btn-secondary addbtn  rounded-pill " >  +  </a>
          </small>
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>

          <table class="table table-bordered table-striped">

            <thead>
             <tr>
              <th>#</th>
              <th>Prélèvement</th>
              <th>Paramètres</th>
              <th>Val. Trouvée</th>
              <th>Val. de Reférence</th>
              <th>Unité</th>
              <th>Observation </th>
            </tr>

          </thead>
          <tbody>

            <tr>
             <td> 1</td>
             <td>Sérum</td>
             <td>Ig Totale</td>
             <td>25</td>
             <td>30-65</td>
             <td>mg/l</td>
             <td></td>

           </tr>

           <tr>
            <td>2</td>
            <td>LCS</td>
            <td>Créatinine</td>
            <td>45</td>
            <td>45-53</td>
            <td>mg/l</td>
            <td></td>


          </tr>

          <tr>
            <td>3</td>
            <td>Urine</td>
            <td>Calucium</td>
            <td>58</td>
            <td>22-26</td>
            <td>g/L</td>
            <td></td>


          </tr>

          <tr>
            <td>4</td>
            <td>Sérum</td>
            <td>Acide urique</td>
            <td>75</td>
            <td>45 -63</td>
            <td>g/24h</td>
            <td></td>


          </tr>


        </tbody>

      </table>


      <div>
        <br>

        <b> Interprétation </b>

        <editor v-model="Interrogatoires"
        api-key="o5x47xox9bijhthifgpj0q0mv82126zwljmnyz1bdfbget72"
        :init="{
         height: 150,
         menubar: false,
         plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
         'bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | '
       }"
       />

     </div>

     <div>
      <br>

      <b>Réalisé par  </b>

      <select v-model="operator" name="form-control" id="">
        <option value=""> Selectionner l'agent </option>
      </select>

      <br>
      <button class="btn btn-sm addbtn text-white p-1 float-right "> <small> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Fiche de résultat </small> </button>
    </div>
<br>
 </b-card-body>
  </b-collapse>
</b-card>

<b-card no-body class="mb-1">
  <b-card-header header-tag="header" class="p-0" role="tab">
    <b-button class="p-1" block v-b-toggle.accordion-2 variant="secondary">Anciens Examens </b-button>
  </b-card-header>
  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
    <b-card-body>

L'Historique  des exames ici

    </b-card-body>
  </b-collapse>
</b-card>
</div>






</div>


</template>

<script>

import Axios from "@/_services/caller.services";
import Editor from '@tinymce/tinymce-vue'
export default {

  name: "Laboratoire",
  components: {
   'editor': Editor,

 },

 props : ["movment_id", "services_id"],
 data() {
  return {
    showform: false,
    allergiesSpinner: false,
    Interrogatoires: false,
    allergies: [],
    description:"",
    operator:"",
    movment :{},
    name:"",
  };
},

mounted() {
  this.getMovemt();
},

methods: {
 getMovemt() {

  Axios.get("/movments/"+ this.movment_id)
  .then(
    (res) => {
      this.movment = res.data.data;
      this.getExams(this.movment.patients_id);
      console.log(this.movment);

    })
  .catch((error) => {
    console.error(error);
  });
},

getExams(patients_id) {
  this.allergiesSpinner = true;
  Axios.get("/allergies?patients_id="+ patients_id)
  .then(
    (res) => {
      this.allergies = res.data.data;
      this.allergiesSpinner = false;
      console.log(this.allergies);
    })
  .catch((error) => {
    console.error(error);
  });
},

saveExams() {
  this.allergiesSpinner = true;
  Axios.post("/allergies",{
    name : this.name,
    patients_id : this.movment.patients_id,
    description : this.description,
    movments_id : this.movment_id,
  },
  {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(
    (res) => {
     this.newAllergie = res.data.data;
     this.allergies.push(this.newAllergie)
     this.name = "";
     this.description = "";
     this.showform = false;
     this.allergiesSpinner = false;
     console.log(this.newAllergie);
   })
  .catch((error) => {
    console.error(error);
  });

},

deleteAllergie(id) {
  this.allergiesSpinner = true;
  Axios.post("/allergies/delete",{
    id : id
  },
  {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(
    (res) => {
      this.getAllergies(this.movment.patients_id);
    })
  .catch((error) => {
    console.error(error);
  });
},
}

};




</script>


<style>
.addbtn { padding:0px 5px; background-color:green}

</style>
