<template>
  <section class="container-scroller">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" >
            <div class="row">
              <div class="col-lg-9">
               <h6 class="text-black p-2" style="background-color: #d5e9ea" >
                <div v-if="treatmentSpinner" class="spinner-border spinner-border-sm text-primary" style="font-size:11px" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Patient  : <b> {{   movment.ipp }} ¤  {{ movment.lastname }} {{ movment.firstname }}    </b>   <br>   </b>
                <small> Venu le  <b>{{ movment.human_arrival_date}}</b> </small>&nbsp; <small>  Service de traitement   : <b>  {{ movment.services_name }} </b> </small>
              </h6>
            </div>

            <div class="col-lg-3">
             <button @click = "switshService(movment.id)" class="btn btn-success btn-medpay-green"> Ajouter un service </button>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <br>




  <div class="row" v-if="subComponent.consultation">

    <div class="col-lg-5">

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="secondary">Actes Médicaux & Médicaments</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">

            <ActsComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></ActsComponent>

          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="secondary">Données Patient</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>


             <MeasurementComponent
             :movment_id ="movment_id"
             :services_id = "movment.services_id">
           </MeasurementComponent>

           <PatientHistoy
           :movment_id ="movment_id"
           :services_id = "movment.services_id">
         </PatientHistoy>

         <AllergieComponent
         :movment_id ="movment_id"
         :services_id = "movment.services_id" >
       </AllergieComponent>

       <LivestyleComponent
       :movment_id ="movment_id"
       :services_id = "movment.services_id" >
     </LivestyleComponent>

   </b-card-body>
 </b-collapse>
</b-card>
</div>
</div>

<div class="col-lg-7">
  <div class="card p-0" style="height:730px; font-size:13px;  overflow:auto">
   <div class="card-body p-0 ">
    <ConsultationComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></ConsultationComponent>
  </div></div></div>
</div>

<!-- Inclusion des modules des services -->

<div class="row" v-if="subComponent.laboratoire">
 <div class="col-lg-12">
  <LaboratoireComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></LaboratoireComponent>
</div>
</div>
</div>

<div class="row" v-if="subComponent.imagerie">
 <div class="col-lg-12">
  <ImagerieComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></ImagerieComponent>
</div>
</div>

<div class="row" v-if="subComponent.pediatrie">
 <div class="col-lg-12">
  <PediatrieComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></PediatrieComponent>
</div>
</div>

<div class="row" v-if="subComponent.maternite">
 <div class="col-lg-12">
   <MaterniteComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></MaterniteComponent>
 </div>
</div>

<div class="row" v-if="subComponent.chirurgie">
 <div class="col-lg-12">
  <ChirurgieComponent :movment_id ="movment_id" :services_id = "movment.services_id" ></ChirurgieComponent>
</div>
</div>




<div class="row mt-5" >
  <div class="col-lg-12">
    <div class="mx-auto text-center" >
     <router-link to="/movments/list"><button type="button" class="btn btn-sm btn-secondary btn-medpay-green-small  mr-2  float-left">
     Allez aux venues
    </button> </router-link>

     <router-link to="/payement/list"><button type="button" class="btn btn-sm btn-secondary btn-medpay-green-small  mr-2  float-left">
     Allez à la facturation
    </button> </router-link>

    <button @click="out(movment_id)"  class="btn btn-danger btn-medpay-green-small  mr-2  float-right"> Quitter </button>

    <!-- <button type="button" class="btn btn-secondary btn-medpay-green-small  mr-2 float-right"> Envoyer vers un autre services  </button>-->

  </div>
</div>
</div>

</section>
</template>


<script>

//import MedicationComponent from "./treatment/medication";
import AllergieComponent from "./treatment/allergie";
import ActsComponent from "./treatment/acts";
import PatientHistoy from "./treatment/patientHistoy";
import LivestyleComponent from "./treatment/livestyle";
import MeasurementComponent from "./treatment/measurement";


import ConsultationComponent from "./services/consultation";
import ImagerieComponent from "./services/imagerie";
import PediatrieComponent from "./services/pediatrie";
import LaboratoireComponent from "./services/laboratoire";
import MaterniteComponent from "./services/maternite";
import ChirurgieComponent from "./services/chirurgie";

import { useToast, POSITION } from "vue-toastification";
import Axios from "@/_services/caller.services";

export default {
  components : {
    ActsComponent,
    PatientHistoy,
    AllergieComponent,
    LivestyleComponent,
    MeasurementComponent,
    ConsultationComponent,
    ImagerieComponent,
    PediatrieComponent,
    LaboratoireComponent,
    MaterniteComponent
  },

  props : ["movment_id", "selectedModule"],

  data() {
    return {
      subComponent: {
        consultation: "",
        laboratoire: "",
        imagerie: "",
        pediatrie: "",
        maternite: "",
        chirurgie: ""
      },

      movment: {},
      services_id: "",
      treatmentSpinner: false,
      showServiceSlector: false,
      formErrors :{}
    };
  },
  mounted() {
    this.getMovemt();

  },

  methods: {

    loadSubCompoment(){

      this.selectedModule === "consultation" ? this.subComponent.consultation=true : this.subComponent.consultation=false;
      this.selectedModule === "laboratoire" ? this.subComponent.laboratoire=true :this.subComponent.laboratoire=false;
      this.selectedModule === "imagerie" ? this.subComponent.imagerie=true : this.subComponent.imagerie=false;
      this.selectedModule === "pediatrie" ? this.subComponent.pediatrie=true : this.subComponent.pediatrie=false;
      this.selectedModule === "maternite" ? this.subComponent.maternite=true : this.subComponent.maternite=false;
      this.selectedModule === "chirurgie" ? this.subComponent.chirurgie=true : this.subComponent.chirurgie=false;
    },

    getMovemt() {
      this.treatmentSpinner = true ;
      Axios.get("/movments/"+ this.movment_id)
      .then(
        (res) => {
          this.movment = res.data.data;
          this.treatmentSpinner = false ;
          this.selectedModule = this.movment.services_code;
          this.loadSubCompoment();
          console.log(this.movment);
        })
      .catch((error) => {
        console.error(error);
      });
    },

    out(id) {
      this.$router.push("/movments/finish/" + id);
    },
    switshService(id){
      this.$router.push("/movments/switcher/" + id);
    }
  }


};


</script>

<style>
.col-lg-4{

}
.addbtn { padding:0px 5px; background-color:green }
</style>



