var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"p-1",attrs:{"block":"","variant":"secondary"}},[_vm._v("Nouveaux Examens ")]),_c('small',{staticClass:"float-right"},[_c('a',{staticClass:"btn btn-sm btn-secondary addbtn rounded-pill",on:{"click":function($event){_vm.showform=true}}},[_vm._v(" + ")])])],1),_c('b-collapse',{attrs:{"id":"accordion-1","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Prélèvement")]),_c('th',[_vm._v("Paramètres")]),_c('th',[_vm._v("Val. Trouvée")]),_c('th',[_vm._v("Val. de Reférence")]),_c('th',[_vm._v("Unité")]),_c('th',[_vm._v("Observation ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(" 1")]),_c('td',[_vm._v("Sérum")]),_c('td',[_vm._v("Ig Totale")]),_c('td',[_vm._v("25")]),_c('td',[_vm._v("30-65")]),_c('td',[_vm._v("mg/l")]),_c('td')]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("LCS")]),_c('td',[_vm._v("Créatinine")]),_c('td',[_vm._v("45")]),_c('td',[_vm._v("45-53")]),_c('td',[_vm._v("mg/l")]),_c('td')]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("Urine")]),_c('td',[_vm._v("Calucium")]),_c('td',[_vm._v("58")]),_c('td',[_vm._v("22-26")]),_c('td',[_vm._v("g/L")]),_c('td')]),_c('tr',[_c('td',[_vm._v("4")]),_c('td',[_vm._v("Sérum")]),_c('td',[_vm._v("Acide urique")]),_c('td',[_vm._v("75")]),_c('td',[_vm._v("45 -63")]),_c('td',[_vm._v("g/24h")]),_c('td')])])]),_c('div',[_c('br'),_c('b',[_vm._v(" Interprétation ")]),_c('editor',{attrs:{"api-key":"o5x47xox9bijhthifgpj0q0mv82126zwljmnyz1bdfbget72","init":{
         height: 150,
         menubar: false,
         plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
         'bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | '
       }},model:{value:(_vm.Interrogatoires),callback:function ($$v) {_vm.Interrogatoires=$$v},expression:"Interrogatoires"}})],1),_c('div',[_c('br'),_c('b',[_vm._v("Réalisé par ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.operator),expression:"operator"}],attrs:{"name":"form-control","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.operator=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v(" Selectionner l'agent ")])]),_c('br'),_c('button',{staticClass:"btn btn-sm addbtn text-white p-1 float-right"},[_c('small',[_c('i',{staticClass:"fa fa-file-pdf-o",attrs:{"aria-hidden":"true"}}),_vm._v(" Fiche de résultat ")])])]),_c('br')])],1)],1),_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"p-1",attrs:{"block":"","variant":"secondary"}},[_vm._v("Anciens Examens ")])],1),_c('b-collapse',{attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_vm._v(" L'Historique des exames ici ")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"card-title text-white rounded p-0 mb-1 text-center",staticStyle:{"background-color":"#0B5D3F","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)"}},[_c('small',[_vm._v("Laboratoires")])])
}]

export { render, staticRenderFns }